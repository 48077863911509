<template>
  <div class="kcxfz-container">
    <div class="banner_kcxfz">
      <i
        style="
          background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/banner_kcxfz.jpg);
        "
      ></i>
      <div class="margin width1480 hidden a-fdB">全球化布局</div>
    </div>
    <div class="margin width1480 hidden kcxfz_con">
      <div class="world-view-img-div">
        <img
          src="https://img.cdn.wxzhida.cn/shzd-web-site/images/world-view-02.png"
          class="world-view-img"
        />
      </div>

      <!-- <div class="world-view-img-div">
        <img
          src="https://img.cdn.wxzhida.cn/shzd-web-site/images/world-view-01.png"
          class="world-view-img"
        />
      </div> -->
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive } from 'vue'
export default {
  name: 'worldview',
  components: {},
  setup() {
    const { proxy } = getCurrentInstance()
    onMounted(() => {
      document.title = '全球化布局'

      // 滚动到页面最上方
      window.scrollTo(0, 0)
    })
    return {}
  },
}
</script>

<style lang="less" scoped>
.world-view-img-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
</style>
